import _svg from "property-information/svg";
import _svgCaseSensitiveTagNames from "./svg-case-sensitive-tag-names.json";
import _factory from "./factory";
var exports = {};
var schema = _svg;
var caseSensitive = _svgCaseSensitiveTagNames;
var factory = _factory;
var svg = factory(schema, "g", caseSensitive);
svg.displayName = "svg";
exports = svg;
export default exports;